.bookingCartWrapper {
  position: sticky;
  align-self: flex-end;
  border-radius: 15px;
  background-color: white;
  margin-right: 2.75em;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
  top: 25px;
  // overflow: hidden;
  padding-bottom: 15px;
  .bookingCartCloseWrapper {
    display: none;
  }
  .priceWrapper {
    position: relative;
    width: 90%;
    display: flex;
    text-align: right;
    margin: 15px;
    .priceHeader {
      font-size: 12px;
      color: var(--secondaryColor);
      font-weight: 600;
    }
    .pricePayable {
      color: black;
      font-weight: 600;
      font-size: 35px;
    }
  }
  .serviceWrapper {
    position: relative;
    width: 85%;
    padding: 10px 15px 0px 15px;
    display: flex;
    justify-content: center;
    border: 0.5px solid #d9d9d9;
    border-radius: 15px;
    align-self: center;
    margin-top: 15px;
    .serviceDetailsWrapper {
      position: relative;
      width: 100%;
      .serviceNameWrapper {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .serviceName {
          position: relative;
          width: 70%;
          word-wrap: break-word;
          font-size: 12.5px;
          font-weight: 600;
          margin-top: auto;
          margin-bottom: auto;
        }
        .removeService {
          margin-top: auto;
          margin-bottom: auto;
          cursor: pointer;
          .removeServiceIcon {
            color: var(--secondaryColor);
          }
          .removeServiceIcon:hover {
            color: black;
          }
        }
      }
    }
    .paxWrapper {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;
      .adultsInfo {
        position: relative;
        width: 100%;
        padding: 2px;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        justify-content: center;
        .numAdults {
          position: relative;
          width: 100%;
          word-wrap: break-word;
          font-size: 17.5px;
          font-weight: 500;
        }
        .adjustQty {
          margin-top: -5px;
        }
      }
      .addLabel {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 2px;
        margin-top: auto;
        margin-bottom: auto;
      }
      .childInfo {
        position: relative;
        width: 100%;
        padding: 2px;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        justify-content: center;
        text-align: right;
        align-items: flex-end;
        .numChild {
          position: relative;
          width: 100%;
          word-wrap: break-word;
          font-size: 17.5px;
          font-weight: 500;
        }
        .adjustQty {
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-top: -5px;
        }
      }
      #minus {
        font-size: 25px;
        margin-right: 15px;
        color: var(--secondaryColor);
        cursor: pointer;
      }
      #minus:hover {
        color: red;
      }
      #plus {
        font-size: 25px;
        color: var(--secondaryColor);
        cursor: pointer;
        height: 25px;
      }
      #plus:hover {
        color: green;
      }
    }
  }
  .priceBreakupWrapper {
    position: relative;
    width: 85%;
    padding: 10px 15px 10px 15px;
    display: flex;
    justify-content: center;
    border: 0.5px solid var(--borderColor);
    border-radius: 15px;
    align-self: center;
    margin-top: 15px;
    .header {
      font-size: 15px;
      font-weight: 500;
    }
    .priceBreakup {
      margin-top: 10px;
      .entityWrapper {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 5px;
        .entityName {
          position: relative;
          width: 60%;
          word-wrap: break-word;
          font-size: 12px;
          color: var(--secondaryColor);
          font-weight: 700;
          margin-top: auto;
          margin-bottom: auto;
        }
        .entityPrice {
          position: relative;
          width: 40%;
          word-wrap: break-word;
          font-size: 15px;
          font-weight: 600;
          display: flex;
          justify-content: flex-end;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }
  }
  .tncWrapper {
    position: relative;
    width: 85%;
    padding: 10px 15px 10px 15px;
    display: flex;
    justify-content: center;
    border: 0.5px solid var(--borderColor);
    border-radius: 15px;
    align-self: center;
    margin-top: 15px;
    .tnc {
      position: relative;
      display: flex;
      flex-direction: row;
      .text {
        font-size: 13.5px;
        color: var(--secondaryColor);
      }
      .text u {
        cursor: pointer;
      }
    }
    .warning {
      position: relative;
      font-size: 12px;
      color: red;
      margin-top: 10px;
    }
  }
  .cartCtaBtnWrapper {
    margin-top: 25px;
    position: relative;
    display: flex;
    width: 90%;
    justify-content: center;
    align-self: center;
    .cartCtaBtn {
      background-image: var(--sfgradient);
      color: white;
      text-transform: none;
      border-radius: 25px;
    }
    .cartCtaBtn:disabled {
      color: var(--secondaryColor);
      background-image: none;
      background-color: var(--borderColor);
    }
  }
  .emptyCartText {
    position: relative;
    margin-top: 25px;
    color: var(--secondaryColor);
    text-align: center;
    padding: 10px;
    word-wrap: break-word;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .bookingCartWrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 0px;
    padding-top: 10px;
    box-shadow: none;
    height: fit-content;
    max-height: 90vh;
    .bookingCartCloseWrapper {
      position: relative;
      width: 100%;
      display: flex;
      align-items: flex-end;
      .bookingCartCloseBtn {
        position: relative;
        width: fit-content;
        margin-right: 20px;
      }
    }
    .cartCtaBtnWrapper {
      display: none;
    }
  }
  .bookingCartWrapper::-webkit-scrollbar {
    display: none;
  }
}
