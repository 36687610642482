@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .mobileRoomCharterWrapper {
    position: relative;
    width: 100%;
    margin-top: 25px;
    justify-content: center;
    .header {
      font-size: 18px;
      font-weight: 600;
      padding: 0px 0px 10px 20px;
    }
  }
}
