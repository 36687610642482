.propertyBasicInfoWrapper {
  position: relative;
  width: 100%;
  // margin-top: -150px;
  height: 250px;
  max-height: 350px;
  .propertyBasicInfo {
    position: relative;
    width: fit-content;
    border: none;
    border-radius: 10px;
    background-color: black;
    // padding-bottom: 10px;
    .info {
      border: none;
      border-radius: 10px 10px 0px 0px;
      position: relative;
      display: flex;
      cursor: pointer;
      padding: 10px 12.5px 10px 12.5px;
      .icon {
        margin-top: auto;
        margin-bottom: auto;
      }
      .label {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
  .infoDetailsWrapper {
    position: relative;
    background-color: white;
    color: black;
    padding: 5px;
    border: none;
    height: fit-content;
    overflow: hidden;
    overflow-y: scroll;
    width: 50%;
    margin-top: -5px;
    border-radius: 0px 15px 0px 0px;
    .stayInfo {
      padding: 10px;
      .guestInfo {
        border: 1px solid #c4c4c4;
        border-radius: 15px;
        padding: 15px 15px 7.5px 15px;
        max-height: 75px;
        .qtyBtn {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          margin-top: 2px;
          #plus {
            color: #a0a0a0;
          }
          #minus {
            color: #a0a0a0;
          }
          #plus :hover {
            cursor: pointer;
            padding: 1px;
            border: none;
            border-radius: 50%;
            background-color: green;
            color: white;
          }
          #minus :hover {
            cursor: pointer;
            padding: 1px;
            border: none;
            border-radius: 50%;
            background-color: red;
            color: white;
          }
        }
      }
      .guestInfo strong {
        font-size: 8px;
        color: #808080;
      }
      .guestNum {
        font-weight: 600;
        font-size: 18px;
        margin-top: 5px;
      }
      .stayDatesWrapper {
        position: relative;
        border: 1px solid #c4c4c4;
        border-radius: 15px;
        padding: 7.5px 15px 5px 15px;
        width: 100%;
        max-height: 125px;
        cursor: pointer;
        #heading {
          position: relative;
          width: 100%;
          display: grid;
          grid-template-columns: 50% 50%;
        }
        .checkinDate {
          margin-top: auto;
          margin-bottom: auto;
        }
        .checkinDate strong {
          font-size: 10px;
          color: #808080;
        }
        .checkoutDate {
          position: relative;
          display: flex;
          justify-content: flex-start;
          margin-top: auto;
          margin-bottom: auto;
        }
        .checkoutDate strong {
          font-size: 10px;
          color: #808080;
        }
        .stayDates {
          position: relative;
          width: 100%;
          display: grid;
          grid-template-columns: 50% 50%;
          .cin {
            font-weight: 600;
            position: relative;
            width: 100%;
            word-wrap: break-word;
            padding: 5px;
            font-size: 18px;
          }
          .cout {
            font-weight: 600;
            position: relative;
            width: 100%;
            word-wrap: break-word;
            padding: 5px;
            font-size: 18px;
          }
          .cinTime {
            position: relative;
            width: 100%;
            padding: 5px;
            font-size: 15px;
            font-weight: 400;
          }
          .coutTime {
            position: relative;
            width: 100%;
            padding: 5px;
            font-size: 15px;
            font-weight: 400;
          }
        }
      }
    }
    .detailsWrapper {
      padding: 10px;
      .propertyName {
        font-weight: 600;
        font-size: 22.5px;
      }
      .propertyDesc {
        position: relative;
        width: 100%;
        margin-top: 5px;
        color: var(--secondaryColor);
        font-size: 10px;
        word-wrap: break-word;
      }
      .propContactDetailsWrapper {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 5px;
        .contactWrapper {
          #contact {
            font-size: 10px;
            font-weight: 500;
          }
        }
        .emailWrapper {
          display: flex;
          text-align: flex-start;
          #email {
            font-size: 10px;
            font-weight: 500;
          }
        }
      }
    }
    .amenitiesWrapper {
      padding: 10px;
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .amenities {
        position: relative;
        margin-right: 10px;
        margin-bottom: 10px;
        .amenityIcon {
          margin-top: auto;
          margin-bottom: auto;
        }
        .amenityName {
          margin-top: auto;
          margin-bottom: auto;
          margin-left: 2px;
          font-size: 10px;
        }
      }
    }
  }
}
