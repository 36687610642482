@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap");

// Defining the variables
:root {
  --mavenpro: "Maven Pro", sans-serif;
  --sfgradient: linear-gradient(-150deg, #1ab394, #0d9fa2);
  --small: 320px;
  --medium: 480px;
  --secondaryColor: #a0a0a0;
  --hoverBg: #ecedf0;
  --borderColor: #d9d9d9;
  --sfthemeColor: #1ab295;
  --tertiaryColor: rgba(26, 178, 149, 0.11);
}
