.imgCarouselsWrapper {
  position: relative;
  border: none;
  border-radius: 15px;
  overflow: hidden;
  height: 100%;
  .carouselImg {
    object-fit: cover;
    height: 250px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1180px) {
  .imgCarouselsWrapper {
    position: relative;
    border: none;
    border-radius: 15px;
    overflow: hidden;
    height: 200px;
    .carouselImg {
      object-fit: cover;
      height: 200px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .imgCarouselsWrapper {
    position: relative;
    border: none;
    border-radius: 15px;
    overflow: hidden;
    height: 100%;
    .carouselImg {
      object-fit: cover;
    }
  }
}
