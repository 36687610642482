.customModalBox {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  .customModalWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    border: none;
    .headerWrapper {
      position: relative;
      width: 100%;
      padding: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .headerName {
        font-size: 18px;
        font-weight: 600;
        position: relative;
        width: 60%;
        word-wrap: break-word;
        margin-top: auto;
        margin-bottom: auto;
      }
      .headerCancel {
        position: relative;
        display: flex;
        width: 25px;
        font-size: 18px;
        font-weight: 500;
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
        margin-right: 30px;
        justify-content: center;
        #closeIcon {
          font-size: 45px;
          color: var(--secondaryColor);
        }
        #closeIcon:hover {
          color: black;
        }
      }
      // .headerCancel:hover {
      //   background-color: var(--hoverBg);
      //   border-radius: 50%;
      // }
    }
    .contentWrapper {
      position: relative;
      width: 100%;
      padding: 15px;
      font-size: 13.5px;
      overflow-y: scroll;
    }
    .modalCtas {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-top: 15px;
      padding: 0px 10px 10px 10px;
      width: 100%;
      justify-content: space-between;
      .cancelBtn {
        width: fit-content;
        color: black;
        text-transform: capitalize;
        border-radius: 25px;
      }
      .confirmBtn {
        border: none;
        background-image: var(--sfgradient);
        color: white;
        width: fit-content;
        padding: 5px;
        border-radius: 25px;
        text-transform: capitalize;
        margin-right: 30px;
        font-size: 13.5px;
      }
    }
  }
}
.customModalBox::-webkit-scrollbar {
  display: none;
}

.customModalBox {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
