.heroImgWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  width: 100%;
  align-self: center;
  .galleryWrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    height: 54vh;
    justify-content: center;
    position: relative;
    overflow: visible;
    .coverImage {
      position: relative;
      height: 55vh;
      width: 100%;
      margin-top: auto;
      border-radius: 15px 0px 0px 15px;
      margin-bottom: auto;
      background-image: "white";
      text-align: center;
      .coverImg {
        height: 55vh;
        width: 100%;
        cursor: pointer;
        border-radius: 15px 0px 0px 15px;
      }
      .emptyPlaceholder {
        margin: auto;
        align-self: center;
        height: 55vh;
        object-fit: cover;
        width: 100%;
        // margin-top: 75px;
      }
    }
    .otherImages {
      position: relative;
      width: 100%;
      margin-top: auto;
      margin-bottom: auto;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .otherImgWrapper {
        position: relative;
        width: 47.5%;
        height: 27vh;
        overflow: hidden;
        // margin-bottom: 15px;
        // background-image: var(--backgroundImg);
        background-image: "white";
        text-align: center;
        .otherImg {
          position: relative;
          height: 27vh;
          width: auto;
          // margin-bottom: 15px;
          cursor: pointer;
        }
        .emptyPlaceholder {
          margin: auto;
          align-self: center;
          object-fit: cover;
          height: 27vh;
          width: 100%;
          // margin-top: 15px;
        }
      }
      #sideImg1 {
        padding: 0px 5px 0px 7px;
      }
      #sideImg2 {
        padding: 0px 0px 2px 3px;
        border-top-right-radius: 15px;
      }
      #sideImg3 {
        padding: 5px 0px 0px 7px;
      }
      #sideImg4 {
        padding: 5px 0px 0px 8px;
        border-bottom-right-radius: 15px;
      }
    }
  }
}
