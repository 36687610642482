@import "./styles/variables.scss";
@import "./styles/fallbackLoader.scss";
@import "./styles/header.scss";
@import "./styles/heroImg.scss";
@import "./styles/propBasicInfo.scss";
@import "./styles/pages.scss";
@import "./styles/roomCharter.scss";
@import "./styles/customModal.scss";
@import "./styles/bookingCart.scss";
@import "./styles/mobileHeader.scss";
@import "./styles/imgCarousel.scss";
@import "./styles/mobileHeroImg.scss";
@import "./styles/mobileFooter.scss";
@import "./styles/mobilePropDetails.scss";
@import "./styles/amenitiesCharter.scss";
@import "./styles/bookingDetailsForm.scss";
@import "./styles/bookingConfirmation.scss";
@import "./styles/mobileCalendar.scss";
@import "./styles/mobileRoomCharter.scss";
@import "./styles/mobileCalHeader.scss";
@import "./styles/calendar.scss";
@import "./styles/timeComponent.scss";
@import "./styles/mobileCart.scss";
@import "./styles/bookingFailure.scss";

body {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
  font-family: var(--mavenpro);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Media queries
@media only screen and (min-width: 320px) and (max-width: 1024px) {
  body {
    margin: 0px;
  }
}
