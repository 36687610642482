.bookingFailureWrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .failedImg {
    width: 350px;
    margin-top: 25px;
  }
  .errorMsg {
    margin-top: 25px;
    font-size: 20px;
    font-weight: 700;
  }
  .bookNewRoomBtn {
    margin-top: 45px;
    .btn {
      width: fit-content;
      padding: 5px 10px 5px 10px;
      background-color: transparent;
      text-transform: none;
      font-size: 18px;
      background-image: var(--sfgradient);
      color: white;
      border: none;
    }
  }
}
