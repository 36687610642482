.mobileCalHeaderWrapper {
  position: sticky;
  width: 100%;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  .mobileCalHeader {
    position: relative;
    width: 88%;
    border: 1px solid var(--borderColor);
    border-radius: 10px;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 8px 10px 8px;
    text-align: center;
    .date {
      position: relative;
      width: 48%;
      padding: 5px 8px 5px 8px;
      font-weight: 600;
      font-size: 12px;
      margin-top: auto;
      margin-bottom: auto;
    }
    .transferIcon {
      position: relative;
      width: 4%;
      margin-top: auto;
      margin-bottom: auto;
      display: flex;
      margin-right: 10px;
    }
  }
}
