.bookingConfWrapper {
  position: relative;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -45px;
  .confirmedIcon {
    display: flex;
    width: 25px;
    height: 25px;
    padding: 10px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-image: var(--sfgradient);
    z-index: 100;
    box-shadow: 0px 5px 0px 7.5px #fff;
  }
  .confDetails {
    position: relative;
    width: 100%;
    display: flex;
    border: none;
    border-radius: 15px;
    padding: 40px 10px 25px 10px;
    background-color: var(--tertiaryColor);
    margin-top: -15px;
    color: black;
    justify-content: center;
    text-align: center;
    align-items: center;
    .guestNameMsg {
      font-size: 15px;
      font-weight: 600;
    }
    .emailSentText {
      color: var(--secondaryColor);
      font-size: 15px;
      margin-top: 10px;
      font-weight: 600;
    }
    .bookingDetails {
      margin-top: 25px;
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      .guestNumWrapper {
        position: relative;
        width: fit-content;
        border: 1px solid var(--borderColor);
        border-radius: 15px;
        padding: 10px;
        display: flex;
        justify-content: flex-start;
        .header {
          font-size: 10px;
          font-weight: 600;
          color: var(--secondaryColor);
        }
        .guestName {
          font-size: 18px;
          font-weight: 600;
        }
      }
      .bookingDate {
        position: relative;
        width: fit-content;
        border: 1px solid var(--borderColor);
        border-radius: 15px;
        padding: 10px;
        height: fit-content;
        display: flex;
        .dateWrapper {
          .header {
            font-size: 10px;
            font-weight: 600;
            color: var(--secondaryColor);
          }
          .date {
            font-size: 18px;
            font-weight: 600;
          }
          .time {
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
    }
    .moreInfoWrapper {
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 40px;
      .infoWrapper {
        display: flex;
        .header {
          font-size: 12px;
          font-weight: 600;
        }
        .label {
          font-size: 12px;
          font-weight: 600;
          color: var(--secondaryColor);
        }
      }
    }
  }
  .mobileConfDetails {
    display: none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .bookingConfWrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 50px;
    .confirmedIcon {
      display: flex;
      width: 25px;
      height: 25px;
      padding: 10px;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      background-image: var(--sfgradient);
      z-index: 100;
      box-shadow: 0px 5px 0px 7.5px #fff;
    }
    .confDetails {
      position: relative;
      width: 88%;
      display: flex;
      border: none;
      border-radius: 15px;
      padding: 40px 10px 25px 8px;
      background-color: var(--tertiaryColor);
      margin-top: -15px;
      margin-right: 5px;
      color: black;
      justify-content: center;
      text-align: center;
      align-items: center;
      .guestNameMsg {
        font-size: 15px;
        font-weight: 600;
      }
      .emailSentText {
        color: var(--secondaryColor);
        font-size: 15px;
        margin-top: 10px;
        font-weight: 600;
      }
      .bookingDetails {
        display: none;
      }
      .moreInfoWrapper {
        display: none;
      }
      .mobileConfDetails {
        position: relative;
        margin-top: 25px;
        width: 100%;
        display: block;
        .detail {
          position: relative;
          width: 95%;
          display: flex;
          justify-content: center;
          display: grid;
          padding: 5px;
          grid-template-columns: 50% 50%;
          .label {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            color: var(--secondaryColor);
            font-weight: 600;
            font-size: 13.5px;
            margin-top: auto;
            margin-bottom: auto;
            flex-wrap: wrap;
            word-wrap: break-word;
          }
          .value {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            padding-right: 10px;
            font-size: 15px;
            margin-top: auto;
            margin-bottom: auto;
            font-weight: 600;
          }
        }
      }
    }
  }
}
