@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .mobileFooterWrapper {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    border: none;
    outline: none;
    border-radius: 25px 25px 0px 0px;
    padding: 0px 20px 0px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
    .priceDetails {
      display: flex;
      flex-direction: column;
      margin-top: auto;
      margin-bottom: auto;
      .header {
        font-size: 12px;
        font-weight: 600;
        color: var(--secondaryColor);
      }
      .priceAmt {
        font-size: 22px;
        font-weight: 700;
      }
    }
    .footerCtaBtn {
      background-image: var(--sfgradient);
      color: white;
      text-transform: none;
      width: fit-content;
      height: fit-content;
      border: none;
      border-radius: 25px;
      padding: 5px 15px 5px 15px;
      margin-top: auto;
      margin-bottom: auto;
      font-size: 13.5px;
    }
    .footerCtaBtn:active {
      transform: translateY(4px);
    }
  }
}
