// Homepage
.pageWrapper {
  position: relative;
  width: auto;
  .detailPriceWrapper {
    position: relative;
    // width: 100%;
    display: flex;
    // display: grid;
    // grid-template-columns: 70% 30%;
    justify-content: flex-start;
    margin-left: 2%;
    margin-right: 0%;
    height: 100%;
    .detailWrapper {
      position: relative;
      display: flex;
      width: 70%;
      height: 100em;
      margin-top: auto;
      margin-bottom: auto;
      overflow: visible;
      .propBasicInfoWrapper {
        // position: absolute;
        // top: 0;
        position: relative;
        width: 100%;
        margin-top: -5em;
      }
      .charterWrapper {
        position: relative;
        margin-top: 10px;
        height: calc(100vh - 250px);
      }
    }
    .cartWrapper {
      position: relative;
      width: 30%;
      margin-top: -2em;
    }
  }
}
.mobilePageWrapper {
  display: none !important;
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .pageWrapper {
    display: none !important;
  }
  .mobilePageWrapper {
    display: flex !important;
    position: relative;
    justify-content: center;
    overflow: hidden;
    min-height: -webkit-fill-available;
    height: 92vh;
    overflow-y: scroll;
    .content {
      position: relative;
      height: 92vh;
      overflow: hidden;
      overflow-y: scroll;
    }
    .content::-webkit-scrollbar {
      display: none;
    }
    .footerWrapper {
      height: 8vh;
      overflow: hidden;
      padding: 10px 10px 10px 6px;
      // border-top: 1px solid var(--secondaryColor);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }
}

// Add Amenities Page
