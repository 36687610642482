.roomCharterWrapper {
  position: relative;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // margin-bottom: 25px;
  .header {
    font-weight: 600;
    font-size: 15px;
  }
  .roomCharters {
    position: relative;
    width: 100%;
    margin-bottom: 45px;
    display: flex;
    flex-direction: row;
    .roomImgWrapper {
      position: relative;
      width: 32%;
      height: 240px;
      overflow: hidden;
      .roomImg {
        position: relative;
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 25px;
        object-fit: cover;
      }
    }
    .roomInfoWrapper {
      position: relative;
      display: flex;
      width: 60%;
      margin-top: 10px;
      .roomTypeName {
        font-weight: 600;
        font-size: 15px;
      }
      .roomInfo {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 8px;
        .avilabilityWrapper {
          position: relative;
          width: 60%;
          margin-top: auto;
          margin-bottom: auto;
          .availability {
            flex-wrap: wrap;
            font-size: 15px;
          }
          .warningText {
            margin-top: 10px;
            font-size: 12px;
            font-weight: 500;
            color: red;
          }
        }
        .amenities {
          position: relative;
          width: 40%;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          height: 32px;
          margin-top: auto;
          margin-bottom: auto;
          .amenitiesIcons {
            position: relative;
            width: 28px;
            height: 28px;
            border: 0.5px solid var(--secondaryColor);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: -3.5px;
            background-color: #fff;
            font-weight: 600;
            cursor: pointer;
            .amenityIcon {
              size: 10px;
              color: black;
            }
          }
          .amenitiesIcons:hover {
            width: 37.5px;
            height: 37.5px;
          }
        }
      }
      .roomTypeDesc {
        position: relative;
        margin-top: 35px;
        color: var(--secondaryColor);
        font-size: 12px;
      }
      .packageInfoWrapper {
        position: relative;
        width: 100%;
        margin-top: 40px;
        .packageInfo {
          display: grid;
          grid-template-columns: 50% 25% 25%;
          margin-bottom: 7.5px;
          border: 0.5px var(--borderColor) solid;
          padding: 5px 5px 5px 20px;
          border-radius: 25px;
          .ratePlanWrapper {
            margin-top: auto;
            margin-bottom: auto;
            position: relative;
            word-wrap: break-word;
            .ratePlanName {
              font-weight: 500;
              font-size: 13.5px;
              cursor: pointer;
            }
            .ratePlanDesc {
              font-size: 13.5px;
            }
          }
          .roomPrice {
            margin-top: auto;
            margin-bottom: auto;
            font-weight: 600;
          }
          .addBtnWrapper {
            position: relative;
            width: 100%;
            display: flex;
            align-items: flex-end;
            .addBtn {
              position: relative;
              margin-top: auto;
              margin-bottom: auto;
              background-image: var(--sfgradient);
              border-radius: 25px;
              color: white;
              text-transform: capitalize;
              width: fit-content;
              font-size: 12px;
            }
            .addBtn:disabled {
              background-color: var(--secondaryColor);
              background-image: none;
              color: grey;
            }
          }
        }
        .cancellationPolicies {
          position: relative;
          margin-top: 2.5px;
          font-weight: 600;
          text-decoration: underline;
          color: var(--secondaryColor);
          cursor: pointer;
          font-size: 10px;
        }
      }
      .allRatePlansWrapper {
        position: relative;
        width: 100%;
        display: flex;
        align-items: flex-end;
        cursor: pointer;
        margin-top: 5px;
        .btn {
          border-radius: 15px;
          background-color: var(--tertiaryColor);
          width: fit-content;
          padding: 5px 10px 5px 10px;
          color: black;
          font-weight: 600;
          font-size: 12px;
        }
      }
    }
  }
}

// Modals included in this particular page
// All amenities modal
.allAmenitiesWrapper {
  position: relative;
  margin-bottom: 25px;
  .allAmenitiesIcons {
    position: relative;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    border: 1px solid var(--secondaryColor);
    background-color: white;
    width: 45px;
    height: 45px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    .allAmenityIcon {
      font-size: 25px;
      color: black;
    }
  }
  .allAmenityLabel {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 15px;
    font-family: 400;
    color: var(--secondaryColor);
  }
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .roomCharterWrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 5px;
    .header {
      font-weight: 600;
      font-size: 15px;
    }
    .roomCharters {
      position: relative;
      width: 100%;
      margin-bottom: 0px;
      margin-top: 35px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .roomImgWrapper {
        position: relative;
        width: 92%;
        height: 200px;
        overflow: hidden;
        .roomImg {
          position: relative;
          width: 100%;
          height: 100%;
          border: none;
          border-radius: 25px;
          object-fit: cover;
        }
      }
      .roomInfoWrapper {
        position: relative;
        width: 100%;
        display: flex;
        margin-top: 15px;
        .roomTypeName {
          font-weight: 600;
          font-size: 15px;
        }
        .roomInfo {
          position: relative;
          width: 92%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 10px;
          .availability {
            position: relative;
            width: 60%;
            flex-wrap: wrap;
            font-size: 15px;
            margin-top: auto;
            margin-bottom: auto;
          }
          .amenities {
            position: relative;
            width: 40%;
            display: flex;
            align-items: flex-end;
            height: 32px;
            margin-top: auto;
            margin-bottom: auto;
            .amenitiesIcons {
              position: relative;
              width: 25px;
              height: 25px;
              border: 0.5px solid var(--secondaryColor);
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: -3.5px;
              background-color: #fff;
              cursor: pointer;
              .amenityIcon {
                size: 10px;
                color: black;
              }
            }
            .amenitiesIcons:hover {
              width: 30px;
              height: 30px;
            }
          }
        }
        .roomTypeDesc {
          position: relative;
          width: 92%;
          display: flex;
          word-wrap: break-word;
          margin-top: 15px;
          color: var(--secondaryColor);
          font-size: 12px;
        }
        .packageInfoWrapper {
          position: relative;
          width: 92%;
          margin-top: 25px;
          .packageInfo {
            display: grid;
            grid-template-columns: 40% 35% 25%;
            margin-bottom: 7.5px;
            border: 0.5px var(--borderColor) solid;
            padding: 5px 5px 5px 20px;
            border-radius: 25px;
            .ratePlanWrapper {
              position: relative;
              display: flex;
              margin-top: auto;
              margin-bottom: auto;
              .ratePlanName {
                font-weight: 500;
                font-size: 15px;
              }
              .ratePlanDesc {
                font-size: 13.5px;
              }
            }
            .roomPrice {
              position: relative;
              display: flex;
              margin-top: auto;
              margin-bottom: auto;
              font-weight: 600;
            }
            .addBtn {
              position: relative;
              display: flex;
              margin-top: auto;
              margin-bottom: auto;
              background-image: var(--sfgradient);
              border-radius: 25px;
              color: white;
              text-transform: capitalize;
              margin-right: 10px;
            }
          }
          .cancellationPolicies {
            position: relative;
            margin-top: 2px;
            font-weight: 600;
            text-decoration: underline;
            color: var(--secondaryColor);
            cursor: pointer;
            font-size: 10px;
            width: fit-content;
          }
        }
        .allRatePlansWrapper {
          position: relative;
          display: flex;
          width: 92%;
          justify-content: flex-end;
          text-align: right;
          align-items: flex-end;
          cursor: pointer;
          .btn {
            border-radius: 15px;
            background-color: var(--tertiaryColor);
            padding: 5px 10px 5px 10px;
            color: black;
            width: fit-content;
            font-weight: 600;
            font-size: 12px;
          }
        }
      }
    }
  }
}
