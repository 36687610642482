@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .mobilePropInfoWrapper {
    position: relative;
    margin-top: 25px;
    display: flex;
    width: 100%;
  }
  .mobileTabsWrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    // margin: 0px 8px 0px 12px;
    .mobileInfoTab {
      position: relative;
      display: flex;
      .header {
        color: var(--secondaryColor);
        font-weight: 600;
      }
    }
    #stay {
      position: relative;
      width: 30%;
      text-align: center;
    }
    #details {
      position: relative;
      width: 30%;
      text-align: center;
    }
    #amenities {
      position: relative;
      width: 40%;
      text-align: center;
    }
  }
  .tabDescWrapper {
    position: relative;
    width: 100%;
    margin-top: 25px;
    display: flex;
    // padding-left: 15px;
    flex-wrap: wrap;
    .detailsDesc {
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      .hotelName {
        font-size: 22px;
        font-weight: 600;
        text-transform: uppercase;
      }
      .hotelAddress {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        word-wrap: break-word;
        margin-top: 5px;
        font-size: 16px;
        font-weight: 600;
      }
      .hotelDesc {
        position: relative;
        width: 95%;
        flex-wrap: wrap;
        word-wrap: break-word;
        margin-top: 15px;
        font-size: 15px;
        color: var(--secondaryColor);
        overflow-y: scroll;
      }
    }
  }
  .amenitiesWrapper {
    margin-top: 10px;
    padding-left: 15px;
    .amenities {
      margin-bottom: 15px;
      .amenityIcon {
        margin-top: auto;
        margin-bottom: auto;
        .icon {
          color: var(--secondaryColor);
        }
      }
      .amenityName {
        margin-top: -2px;
        margin-bottom: auto;
      }
    }
  }
}
