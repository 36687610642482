.bookingDetailsWrapper {
  position: relative;
  width: 75%;
  margin-top: -25px;
  .header {
    font-size: 18px;
    font-weight: 600;
  }
  .bookingDetails {
    position: relative;
    margin-top: 15px;
    border: 1px solid var(--secondaryColor);
    border-radius: 15px;
    padding: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .detail {
      margin-right: 30px;
      margin-bottom: 25px;
      margin-top: 25px;
      .errorMsg {
        color: red;
        font-size: 10px;
      }
    }
  }
  .refundableBookingWrapper {
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
    display: none;
    .header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      .icon {
        margin-top: auto;
        margin-bottom: auto;
      }
      .label {
        margin-top: auto;
        margin-bottom: auto;
        font-size: 18px;
        margin-left: 15px;
      }
    }
    .refundableBooking {
      position: relative;
      margin-top: 25px;
      border: 1px solid var(--secondaryColor);
      border-radius: 15px;
      height: fit-content;
      width: 100%;
      overflow: hidden;
      .headerItems {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 10px;
        .headerName {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          #refundable {
            margin-top: auto;
            margin-bottom: auto;
          }
          .label {
            margin-top: auto;
            margin-bottom: auto;
            font-size: 15px;
            font-weight: 600;
          }
          .recommended {
            margin-top: auto;
            margin-bottom: auto;
            color: white;
            background-color: var(--sfthemeColor);
            font-size: 12px;
            padding: 5px 8px 5px 8px;
            border-radius: 50px;
          }
        }
        .price {
          margin-top: auto;
          margin-bottom: auto;
          margin-right: 25px;
          font-weight: 600;
        }
      }
      .benefitWrapper {
        padding: 10px;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .benefits {
          position: relative;
          display: flex;
          flex-direction: row;
          margin-right: 10px;
          flex-wrap: wrap;
          .tick {
            margin-top: 5px;
            margin-bottom: auto;
          }
          .benefitName {
            margin-top: auto;
            margin-bottom: auto;
            font-size: 12px;
            margin-left: 5px;
          }
        }
      }
      .viewMoreBtn {
        margin-top: auto;
        margin-bottom: auto;
        display: flex;
        margin-left: 15px;
        font-size: 15px;
        font-weight: 600;
        color: var(--secondaryColor);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .tncWrapper {
    display: none;
  }
}

// Modal dispalying the refundable booking benefits
.allRefundBookingBenefitsWrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  .benefitName {
    display: flex;
    margin-right: 10px;
    margin-bottom: 12px;
    .name {
      font-size: 12px;
      font-weight: 600;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 560px) {
  .bookingDetailsWrapper {
    position: relative;
    width: 100%;
    margin-top: 0px;
    .header {
      font-size: 18px;
      font-weight: 600;
      display: none;
    }
    .bookingDetails {
      position: relative;
      margin-top: 15px;
      border: none;
      border-radius: 0px;
      padding: 25px;
      display: flex;
      flex-direction: column;
      .detail {
        margin-right: 10px;
        margin-bottom: 25px;
        margin-top: 0px;
        width: 100%;
        .errorMsg {
          color: red;
          font-size: 10px;
        }
      }
    }
    .refundableBookingWrapper {
      position: relative;
      margin-top: 5px;
      margin-bottom: 50px;
      width: 100%;
      display: none;
      .header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 10px;
        .icon {
          margin-top: auto;
          margin-bottom: auto;
        }
        .label {
          margin-top: auto;
          margin-bottom: auto;
          font-size: 15px;
          margin-left: 15px;
          font-weight: 500;
        }
      }
      .refundableBooking {
        position: relative;
        margin-top: 15px;
        margin-left: 12px;
        border: 1px solid var(--secondaryColor);
        border-radius: 15px;
        height: fit-content;
        width: 92%;
        overflow: hidden;
        .headerItems {
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: 10px;
          .headerName {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            #refundable {
              margin-top: auto;
              margin-bottom: auto;
            }
            .label {
              margin-top: auto;
              margin-bottom: auto;
              font-size: 12.5px;
              font-weight: 600;
            }
            .recommended {
              margin-top: auto;
              margin-bottom: auto;
              color: white;
              background-color: var(--sfthemeColor);
              font-size: 10px;
              padding: 5px 8px 5px 8px;
              border-radius: 50px;
            }
          }
          .price {
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 20px;
            font-weight: 600;
            font-size: 15px;
          }
        }
        .benefitWrapper {
          padding: 10px;
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          .benefits {
            position: relative;
            display: flex;
            flex-direction: row;
            margin-right: 10px;
            flex-wrap: wrap;
            display: none;
            .tick {
              margin-top: 5px;
              margin-bottom: auto;
            }
            .benefitName {
              margin-top: auto;
              margin-bottom: auto;
              font-size: 12px;
              margin-left: 5px;
            }
          }
        }
        .viewMoreBtn {
          position: relative;
          width: 100%;
          margin-top: auto;
          margin-bottom: auto;
          display: flex;
          margin-left: 70%;
          font-size: 15px;
          font-weight: 600;
          color: var(--secondaryColor);
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .tncWrapper {
      position: relative;
      display: block;
      padding: 0px 25px 5px 25px;
      .tnc {
        position: relative;
        .tnc input {
          margin-top: auto;
          margin-bottom: auto;
        }
        .text {
          color: var(--secondaryColor);
          font-size: 13.5px;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
      .warning {
        margin-top: 10px;
        color: red;
        font-size: 10px;
      }
    }
  }

  // Modal dispalying the refundable booking benefits
  .allRefundBookingBenefitsWrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    .benefitName {
      display: flex;
      margin-right: 10px;
      margin-bottom: 12px;
      .name {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}
