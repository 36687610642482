.timeComponentWrapper {
  position: relative;
  width: 88%;
  margin-top: 25px;
  margin-left: 12px;
  justify-content: center;
}

.dropdownTimeComponent {
  position: relative;
  width: 92%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-bottom: 25px;
  #cinTime {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    position: relative;
    width: 225px;
  }
  #coutTime {
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    width: 225px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .timeComponentWrapper {
    position: relative;
    width: 85%;
    margin-top: 25px;
    margin-left: 12px;
    justify-content: center;
  }

  .dropdownTimeComponent {
    position: relative;
    width: 92%;
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-bottom: 25px;
    #cinTime {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 10px;
    }
    #coutTime {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
