.headerWrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  .hotelName {
    font-weight: 600;
  }
  .support {
    color: var(--secondaryColor);
    font-weight: 600;
    cursor: pointer;
  }
  .profileBg {
    display: flex;
    justify-content: center;
    background-color: rgba(229, 229, 229, 0.5);
    padding: 5px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }
}
