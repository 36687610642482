.mobileDateTimeWrapper {
  position: relative;
  margin: 10px 0px 10px 0px;
  border: 1px solid var(--borderColor);
  border-radius: 15px;
  align-self: center;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  width: 85%;
  .dateTimeWrapper {
    position: relative;
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    padding: 0px 10px 0px 10px;
    .header {
      font-size: 12px;
      color: var(--secondaryColor);
      font-weight: 600;
    }
    .date {
      font-size: 18px;
      font-weight: 600;
      color: black;
    }
    .time {
      font-size: 15px;
    }
  }
  #checkoutDate {
    align-items: flex-end;
  }
}
