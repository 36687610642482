.amenitiesCharterWrapper {
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .amenitiesCharterHeader {
    position: relative;
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
    .header {
      font-size: 15px;
    }
    .skipBtn {
      color: var(--secondaryColor);
      text-decoration: underline;
      font-size: 12px;
      cursor: pointer;
    }
    .skipBtn:hover {
      font-weight: 600;
    }
  }
  .amenitiesInfoWrapper {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .amenitiesCharter {
    position: relative;
    width: 100%;
    margin-bottom: 45px;
    .amenitiesImgWrapper {
      position: relative;
      width: 90%;
      height: 200px;
      overflow: hidden;
      margin-right: 25px;
      .roomImg {
        position: relative;
        width: 100%;
        height: 100%;
        border: none;
        object-fit: cover;
      }
    }
    .amenityInfoWrapper {
      position: relative;
      display: flex;
      margin-top: 15px;
      .amenityName {
        font-weight: 600;
        font-size: 15px;
      }
      .amenityInfo {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
        .availability {
          position: relative;
          width: fit-content;
          flex-wrap: wrap;
          font-size: 15px;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
      .amenityDesc {
        position: relative;
        width: 90%;
        word-wrap: break-word;
        margin-top: 25px;
        color: var(--secondaryColor);
        font-size: 12px;
      }
      .packageInfo {
        position: relative;
        width: 85%;
        display: grid;
        grid-template-columns: 30% 70%;
        margin-top: 25px;
        .cancellationPolicies {
          position: relative;
          font-weight: 600;
          text-decoration: underline;
          color: var(--secondaryColor);
          cursor: pointer;
          font-size: 10px;
          margin-top: auto;
          margin-bottom: auto;
        }
        .amenityPriceAction {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: space-between;
          .amenityPrice {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: auto;
            margin-bottom: auto;
            font-weight: 600;
          }
          .addBtn {
            margin-top: auto;
            margin-bottom: auto;
            background-image: var(--sfgradient);
            border-radius: 25px;
            color: white;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .amenitiesCharterWrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    padding: 10px;
    align-content: center;
    .amenitiesCharterHeader {
      position: relative;
      width: 92%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 25px;
      .header {
        font-size: 13.5px;
        position: relative;
        width: 80%;
        word-wrap: break-word;
      }
      .skipBtn {
        position: relative;
        width: 20%;
        color: var(--secondaryColor);
        text-decoration: underline;
        font-size: 12px;
        cursor: pointer;
        font-weight: 600;
      }
    }
    .amenitiesInfoWrapper {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .amenitiesCharter {
        position: relative;
        width: 100%;
        margin-bottom: 45px;
        display: flex;
        flex-direction: row;
        margin-right: 0px;
        .amenitiesImgWrapper {
          position: relative;
          width: 35%;
          height: 150px;
          overflow: hidden;
          display: flex;
          justify-content: center;
        }
        .amenityInfoWrapper {
          position: relative;
          width: 65%;
          display: flex;
          margin-top: 0px;
          justify-content: flex-start;
          .amenityName {
            font-weight: 600;
            font-size: 15px;
          }
          .amenityInfo {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 8px;
            .availability {
              position: relative;
              width: 60%;
              flex-wrap: wrap;
              font-size: 13.5px;
              margin-top: auto;
              margin-bottom: auto;
            }
          }
          .amenityDesc {
            position: relative;
            width: 90%;
            word-wrap: break-word;
            margin-top: 15px;
            color: var(--secondaryColor);
            font-size: 10px;
          }
          .packageInfo {
            position: relative;
            width: 90%;
            // display: grid;
            // grid-template-columns: 30% 40% 30%;
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            .cancellationPolicies {
              position: relative;
              font-weight: 500;
              text-decoration: underline;
              color: var(--secondaryColor);
              cursor: pointer;
              font-size: 10px;
              // margin-top: auto;
              // margin-bottom: auto;
            }
            .amenityPriceAction {
              position: relative;
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              .amenityPrice {
                position: relative;
                // width: 100%;
                display: flex;
                width: fit-content;
                margin-top: 10px;
                // justify-content: center;
                // margin-top: auto;
                // margin-bottom: auto;
                font-weight: 600;
                font-size: 15px;
              }
              .addBtn {
                position: relative;
                display: flex;
                width: fit-content;
                // margin-left: 20px;
                // margin-top: auto;
                // margin-bottom: auto;
                background-image: var(--sfgradient);
                border-radius: 25px;
                color: white;
                text-transform: capitalize;
                font-size: 10px;
                margin-top: 10px;
                padding-top: 2.5px;
                padding-bottom: 2.5px;
              }
            }
          }
        }
      }
    }
  }
}
