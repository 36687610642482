@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .mobileHeaderWrapper {
    position: sticky;
    top: 0;
    position: relative;
    width: 92%;
    color: black;
    display: flex;
    justify-content: space-between;
    align-self: center;
    margin-bottom: 3px;
    margin-top: 8px;
    cursor: pointer;
    .headerNameWrapper {
      position: relative;
      width: 100%;
      margin-top: auto;
      margin-bottom: auto;
      display: flex;
      // margin-left:
      .backBtn {
        position: relative;
        margin-top: 5px;
        margin-bottom: auto;
        width: fit-content;
      }
      .hotelName {
        position: relative;
        word-wrap: break-word;
        font-size: 15px;
        font-weight: 600;
        margin-top: auto;
        margin-bottom: auto;
        width: fit-content;
      }
    }
    .headerOperations {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 5px 0px 0px 0px;
      margin-top: auto;
      margin-bottom: auto;
      .cartOperation {
        margin-top: auto;
        margin-bottom: auto;
      }
      .hamburger {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
  .mobileHeaderWrapper:focus {
    background-color: transparent;
  }
}
