.mobileCalWrapper {
  position: relative;
  width: 100%;
  padding: 10px;
  font-style: var(--mavenpro);
  margin-top: -15px;
  .mobileCal {
    position: relative;
    width: 92%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .mobileCalWrapper {
    position: relative;
    width: 100%;
    padding: 0px;
    margin-top: 0px;
    .mobileCal {
      position: relative;
      width: 92%;
    }
  }
}
